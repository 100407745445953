export default function Portfolio(){
    return (
        <header className="j-header">
            <div>
                Jana Veliskova
            </div>

            
        </header>
    )
}
