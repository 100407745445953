import Post from './Post';


export default function Blog(){
    return (
        <div>
            This is the blog section
            <Post/>
        </div>
    )
}
